import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Container,
  createTheme,
  Grid,
  Link,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material'

import { ReactComponent as Logo } from './assets/images/klartech-logo.svg'
import { ServicesPage, ContactPage, AboutPage } from './components'
import { SentPage } from './components/Contact/SentPage'
import { MobileHeader } from './components/MobileVersion'

import './App.css'


const LinksTheme = createTheme({
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          margin: '4px',
          padding: '12px 32px',
          color: '#FFFFFF',
          fontFamily: 'Exo',
          fontWeight: 800,
          fontSize: '14px',
          lineHeight: '24px',
          textDecoration: 'none',
          '&.Mui-selected': {
            color: '#FFFFFF',
          },
          '&:nth-Child(3)': {
            border: '1px solid white',
            boxShadow: '4px 4px 0px #FFFFFF',
            '&:hover': {
              background: 'white',
              borderColor: 'black',
              color: 'black',
            },
          },
        },
      },
    },
  },
})

const App: FC = () => {
  const [isSent, setIsSent] = useState<boolean>(false)
  const { t } = useTranslation()
  const matches = useMediaQuery('(max-width:1200px)')
  const matchesMobile = useMediaQuery('(max-width:930px)')

  return (
    <Container
      disableGutters={true}
      maxWidth={false}
      sx={{ margin: 0, width: '100%', overflow: 'hidden' }}
    >
      <Box sx={{ width: '100%' }}>
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            position: 'fixed',
            backgroundColor: '#111111',
            padding: matchesMobile? '14px 24px 14px 24px' : matches ? '24px 44px 0px 44px' : '24px 160px 0px 160px',
            zIndex: '6',
          }}
        >
          <Grid item>
            <Logo style={{ fill: 'white' }} />
          </Grid>
          <Grid item>
            {matchesMobile ? (
              <MobileHeader />
            ) : (
              <ThemeProvider theme={LinksTheme}>
                <Grid container direction={'row'}>
                  <Link href={'#about'}>{t('about', 'ABOUT')}</Link>
                  <Link href={'#services'}>{t('services', 'SERVICES')}</Link>
                  <Link href={'#contact'}>{t('contact', 'CONTACT')}</Link>
                </Grid>
              </ThemeProvider>
            )}
          </Grid>
        </Grid>
        {isSent ? (
          <SentPage setIsSent={setIsSent} />
        ) : (
          <>
            <AboutPage />
            <ServicesPage />
            <ContactPage setIsSent={setIsSent} />
          </>
        )}
      </Box>
    </Container>
  )
}

export default App
