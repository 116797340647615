import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Link } from '@mui/material'

import { ReactComponent as Burger } from '../../assets/images/burger.svg'


export const MobileHeader: FC = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { t } = useTranslation()

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setIsOpen(open)
  }

  const list = () => (
    <Box
      sx={{ width: 'auto', backgroundColor: '#424242', color: 'white' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['about', 'services', 'contact'].map((text) => (
          <ListItem key={text}>
            <Link
              key={text}
              href={`#${text}`}
              sx={{
                width: '100%',
                textDecoration: 'none',
                textTransform: 'capitalize',
                textAlign: 'center',
                color: 'white',
                padding: '10px',
              }}
            >
              <span>{t(`${text}`)}</span>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <Box>
      <Button onClick={toggleDrawer(true)} sx={{ minWidth: 0, padding: 0 }}>
        <Burger />
      </Button>
      <Drawer anchor={'top'} open={isOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </Box>
  )
}
