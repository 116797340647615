import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'
import { Box, Grid, useMediaQuery } from '@mui/material'

import { ReactComponent as ServiceSvgSmall } from '../../../assets/images/VectorSmall.svg'
import { ReactComponent as ServiceLine } from '../../../assets/images/Vector 24 (Stroke).svg'
import TextBox from '../../Common/TextBox'
import { ReactComponent as ConceptPicture } from '../../../assets/images/illustration-concept-code.svg'
import { ReactComponent as TriangPicture } from '../../../assets/images/Rectangle 177.svg'


const StyledSvg = styled(ServiceSvgSmall)`
  width: 305px;
  height: 309px;
  animation: rotate 10s normal linear infinite;
  @keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
const StyledSvgSmall = styled(ServiceSvgSmall)`
  animation: rotateSmall 10s normal linear infinite;
  @keyframes rotateSmall {
    from {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    to {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
`

export default function ServicesBlock() {
  const { t } = useTranslation()
  const matchesMobile = useMediaQuery('(max-width:930px)')
  const matches = useMediaQuery('(max-width:1200px)')
  const matchesPictures = useMediaQuery('(max-width:1350px)')

  const CustomBox = styled(Box)`
    color: white;
    background: #111111;
  `

  return !matchesMobile ? (
    <Box sx={{ position: 'relative' }}>
      <CustomBox
        id={'services'}
        sx={{
          position: 'relative',
          padding: matches ? '100px 44px 140px 44px' : '100px 160px 200px 160px',
        }}
      >
        <Grid container sx={{ position: 'relative' }}>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '64px',
              color: '#FFFFFF',
              fontFamily: 'Exo, Arial',
              fontSize: matches ? '60px' : '72px',
              fontWeight: 800,
              lineHeight: matches ? '72px' : '88px',
            }}
          >
            <span>{t('ourServices', 'Our Services')}</span>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Exo, Arial',
              fontSize: matches ? '15px' : '18px',
              fontWeight: 500,
              lineHeight: matches ? '30px' : '36px',
            }}
          >
            <span>
              {t(
                'servicesText',
                'Our software developers’ tech craftsmanship and business prowess can guarantee tailor-made, scalable solutions that will fuel your business’ growth.',
              )}
            </span>
          </Grid>
          <Box
            sx={{
              position: 'absolute',
              right: matches ? 'calc(10% + 130px)' : 'calc(10% + 150px)',
              top: matches ? 25 : 120,
              zIndex: 2,
            }}
          >
            <StyledSvg style={{ ...(matches ? { width: 255 } : {}) }} />
          </Box>
          <Box sx={{ position: 'absolute', right: 0, top: matches ? 65 : 160, zIndex: 2 }}>
            <StyledSvgSmall style={{ ...(matches ? { width: 195 } : {}) }} />
          </Box>
          <Box sx={{ position: 'absolute', left: 0, top: matches ? -50 : 0, zIndex: 1 }}>
            <ServiceLine style={{ ...(matches ? { width: 933, height: 376 } : {}) }} />
          </Box>
        </Grid>
        <Box sx={{ position: 'absolute', top: 1, right: 0 }}>
          <TriangPicture
            style={{ fill: '#414141', ...(matches ? { width: 500, height: 500 } : {}) }}
          />
        </Box>
      </CustomBox>

      {TextBox(
        `${t('conceptTitle', 'From concept to code')}`,
        `${t(
          'conceptText',
          'Our team of marketers and full-stack web developers will help you build high-performance applications that deliver a unique experience to your users.',
        )}`,
        'linear-gradient(0deg, rgba(17, 17, 17, 0.8), rgba(17, 17, 17, 0.8))',
        <ConceptPicture
          style={{ display: 'block', margin: 'auto', ...(matchesPictures ? { width: 456 } : {}) }}
        />,
        true,
      )}
      <Box sx={{ position: 'absolute', bottom: '-94px', left: 0 }}>
        <TriangPicture style={{ width: '420px', fill: '#111111', transform: 'rotate(180deg)' }} />
      </Box>
    </Box>
  ) : (
    // Mobile version
    <>
      <CustomBox
        id={'services'}
        sx={{
          position: 'relative',
          padding: '50px 24px 30px 24px',
        }}
      >
        <Grid container direction={'row'}>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '24px',
              color: '#FFFFFF',
              fontFamily: 'Exo, Arial',
              fontSize: '32px',
              fontWeight: 800,
              lineHeight: '40px',
              zIndex: 3,
              textAlign: 'center',
            }}
          >
            <span>{t('ourServices', 'Our Services')}</span>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Poppins, Arial',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '28px',
              zIndex: 3,
              textAlign: 'center',
            }}
          >
            <span>
              <Trans i18nKey={'servicesText'}>
                Our software developers’ tech <br /> craftsmanship and business prowess can <br />
                guarantee tailor-made, scalable solutions
                <br /> that will fuel your business’ growth.
              </Trans>
            </span>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '47%' }}>
            <Box
              sx={{
                position: 'relative',
                width: 85,
                zIndex: 2,
              }}
            >
              <StyledSvg style={{ width: 85, height: 85 }} />
            </Box>
            <Box sx={{ width: 65, position: 'relative', zIndex: 2 }}>
              <StyledSvgSmall style={{ width: 65, height: 65 }} />
            </Box>
            <Box sx={{ position: 'absolute', left: 'calc(50% - 160px)', bottom: 30, zIndex: 1 }}>
              <ServiceLine style={{ width: 311, height: 125 }} />
            </Box>
          </Grid>
        </Grid>
      </CustomBox>
      {TextBox(
        `${t('conceptTitle', 'From concept to code')}`,
        `${t(
          'conceptText',
          'Our team of marketers and full-stack web developers will help you build high-performance applications that deliver a unique experience to your users.',
        )}`,
        'linear-gradient(0deg, rgba(17, 17, 17, 0.8), rgba(17, 17, 17, 0.8))',
        <ConceptPicture style={{ display: 'block', margin: 'auto', width: 312, height: 130 }} />,
        true,
      )}
    </>
  )
}
