// eslint-disable-next-line  @typescript-eslint/no-explicit-any
import { FormState } from 'react-hook-form'
import { isEmpty } from 'lodash'

export const focusKeyPressNext = (e: React.KeyboardEvent, formState?: FormState<any>): void => {
  if (e.key === 'Enter') {
    if (
      formState &&
      (!formState.isValid ||
        !formState.isDirty ||
        (formState.isDirty && !isEmpty(formState.errors)))
    ) {
      e.preventDefault()
    }
    const forNextFocus = document.getElementById('forNextFocus')
    const inputs: HTMLInputElement[] = Array.from(
      forNextFocus?.querySelectorAll('input, select, textarea') ?? [],
    )
    const newArrayInputs = inputs.filter((item) => item.id !== 'prefixCode')
    const selectedInput = document.activeElement
    const indexOf = newArrayInputs.findIndex((item) => item.id === selectedInput?.id)
    if (indexOf + 1 < newArrayInputs.length) newArrayInputs[indexOf + 1].focus()
  }
}
