import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'
import { Box, Grid, useMediaQuery } from '@mui/material'

import { ReactComponent as BuildingSvg } from '../../../assets/images/illustration-building.svg'
import BackImgSvg from '../../../assets/images/Rectangle 129.svg'


const StyledBuildingSvg = styled(BuildingSvg)`
  #wind1 {
    animation: lights 1s linear 5s forwards;
  }
  #wind2 {
    animation: lights 1s linear 7s forwards;
  }
  #wind3 {
    animation: lights 1s linear 10s forwards;
  }
  #wind4 {
    animation: lights 1s linear 11s forwards;
  }
  #wind5 {
    animation: lights 1s linear 9s forwards;
  }
  #wind6 {
    animation: lights 1s linear 8s forwards;
  }

  @keyframes lights {
    0% {
      fill: #222222;
    }
    25% {
      fill: #35352d;
    }
    50% {
      fill: #82826e;
    }
    75% {
      fill: #ffffd7;
    }
    100% {
      fill: #ffffd7;
    }
  }
`

export default function AboutBlock() {
  const { t } = useTranslation()
  const matches = useMediaQuery('(max-width:1200px)')
  const matchesMobile = useMediaQuery('(max-width:930px)')

  const CustomBox = styled(Box)`
    color: white;
    background: ${matchesMobile ? '#222222' : '#111111'};
    background-image: url('${BackImgSvg}');
    background-size: cover;
  `

  return !matchesMobile ? (
    <CustomBox
      id={'about'}
      sx={{
        position: 'relative',
        padding: matches ? '160px 44px 132px 44px' : '200px 160px 169px 160px',
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Box
            sx={{ position: 'absolute', left: matches ? '44px' : '160px', bottom: -4, zIndex: 3 }}
          >
            <StyledBuildingSvg style={{ ...(matches ? { width: 282, height: 619 } : {}) }} />
          </Box>
        </Grid>
        <Grid item container xs={6} direction={'row'}>
          <Grid
            item
            sx={{
              marginBottom: matches ? '56px' : '64px',
              color: '#FFFFFF',
              fontFamily: 'Poppins, Arial',
              fontSize: matches ? '15px' : '18px',
              fontWeight: 500,
              lineHeight: matches ? '30px' : '36px',
              textTransform: 'uppercase',
            }}
          >
            <span>{t('aboutUs', 'About us')}</span>
          </Grid>
          <Grid
            item
            alignSelf={'flex-end'}
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Exo, Arial',
              fontSize: matches ? '26px' : '32px',
              fontWeight: 800,
              lineHeight: matches ? '34px' : '40px',
            }}
          >
            <span>
              {t(
                'aboutUsText',
                'With Klartech’s technical know-how and expertise in engineering sophisticated IT systems, ' +
                  'you can achieve your business objectives within your desired deadline and budget.',
              )}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </CustomBox>
  ) : (
    // Mobile version
    <CustomBox
      id={'about'}
      sx={{
        position: 'relative',
        padding: '55px 24px 0 24px',
        backgroundImage: 'none',
      }}
    >
      <Grid container direction={'row'}>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: '24px',
            color: '#FFFFFF',
            fontFamily: 'Exo, Arial',
            fontSize: '14px',
            fontWeight: 800,
            lineHeight: '28px',
            zIndex: 3,
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          <span>{t('aboutUs', 'About us')}</span>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: '32px',
            color: '#FFFFFF',
            fontFamily: 'Exo, Arial',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '26px',
            zIndex: 3,
            textAlign: 'center',
          }}
        >
          {t(
            'aboutUsText',
            'With Klartech’s technical know-how and expertise in engineering sophisticated IT systems, ' +
              'you can achieve your business objectives within your desired deadline and budget.',
          )}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ position: 'relative', zIndex: 3 }}>
            <StyledBuildingSvg
              style={{ display: 'block', margin: 'auto', width: 120, height: 263 }}
            />
          </Box>
        </Grid>
      </Grid>
    </CustomBox>
  )
}
