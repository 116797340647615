import React, { FC } from 'react'

import { Box, useMediaQuery } from '@mui/material'

import { ReactComponent as TriangPicture } from '../../assets/images/Rectangle 177.svg'

export const BlockBox: FC<{ fill: string; children: React.ReactNode }> = ({ fill, children }) => {
  const matches = useMediaQuery('(max-width:1200px)')

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: matches ? '-125px' : '-54px', right: 0 }}>
        <TriangPicture
          style={{
            width: matches ? '350px' : '492px',
            fill,
            transform: 'rotate(-90deg)',
          }}
        />
      </Box>
      {children}
      <Box sx={{ position: 'absolute', bottom: matches ? '-129px' : '-58px', left: 0 }}>
        <TriangPicture
          style={{
            width: matches ? '350px' : '492px',
            fill,
            transform: 'rotate(90deg)',
          }}
        />
      </Box>
    </Box>
  )
}
