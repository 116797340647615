import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@mui/material'

import TextBox from '../../Common/TextBox'
import { BlockBox } from '../../Common'
import { ReactComponent as SaasDevelopmentPicture } from '../../../assets/images/illustration-SAAS-development-services.svg'
import { ReactComponent as ApiSolutionsPicture } from '../../../assets/images/illustration-API-Development-and-system-integration.svg'

export default function DevelopmentBlock() {
  const { t } = useTranslation()
  const matchesPictures = useMediaQuery('(max-width:1350px)')
  const matchesMobile = useMediaQuery('(max-width:930px)')

  return !matchesMobile ? (
    <BlockBox fill={'#111111'}>
      {TextBox(
        `${t('saasTitle', 'SAAS development services')}`,
        `${t(
          'devServiceText',
          'From simple web applications to bespoke CRM systems, we will create ideal solutions,with the highest security standards on any of your preferred cloud systems.',
        )}`,
        '#424242',
        <SaasDevelopmentPicture
          style={{ display: 'block', margin: 'auto', ...(matchesPictures ? { width: 456 } : {}) }}
        />,
      )}
      {TextBox(
        `${t('apiTitle', 'API Development and system integration')}`,
        `${t(
          'devText',
          'We provide reliable, responsive, and custom APIs for all breeds of applications that enable flexible integrations and customisations of existing software products.',
        )}`,
        '#424242',
        <ApiSolutionsPicture
          style={{ display: 'block', margin: 'auto', ...(matchesPictures ? { width: 456 } : {}) }}
        />,
        true,
      )}
    </BlockBox>
  ) : (
    // Mobile version
    <>
      {TextBox(
        `${t('saasTitle', 'SAAS development services')}`,
        `${t(
          'devServiceText',
          'From simple web applications to bespoke CRM systems, we will create ideal solutions,with the highest security standards on any of your preferred cloud systems.',
        )}`,
        '#111111',
        <SaasDevelopmentPicture
          style={{ display: 'block', margin: 'auto', width: 312, height: 130 }}
        />,
      )}
      {TextBox(
        `${t('apiTitle', 'API Development and system integration')}`,
        `${t(
          'devText',
          'We provide reliable, responsive, and custom APIs for all breeds of applications that enable flexible integrations and customisations of existing software products.',
        )}`,
        '#424242',
        <ApiSolutionsPicture
          style={{ display: 'block', margin: 'auto', width: 312, height: 130 }}
        />,
        true,
      )}
    </>
  )
}
