import React, { FC } from 'react'
import TitleBlock from "./Blocks/TitleBlock";
import AboutBlock from "./Blocks/AboutBlock";



export const AboutPage: FC = () => {
  return (
    <>
      {TitleBlock()}
      {AboutBlock()}
    </>
  )
}
