import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'
import { Box, Grid, useMediaQuery } from '@mui/material'

import HOME_BACKGROUND from '../../../assets/images/home background.jpg'
import { ReactComponent as TrianglesBackground } from '../../../assets/images/home-figures-background.svg'


export default function TitleBlock() {
  const { t } = useTranslation()
  const matches = useMediaQuery('(max-width:1200px)')
  const matchesMobile = useMediaQuery('(max-width:930px)')

  const CustomBox = styled(Box)`
    color: white;
    background-image: url('${HOME_BACKGROUND}');
    background-repeat: no-repeat;
    background-size: cover;
  `

  return !matchesMobile ? (
    <CustomBox
      id={'title'}
      sx={{
        position: 'relative',
        padding: matches ? '200px 44px 98px 44px' : '200px 160px 112px 160px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '217px',
          right: matches ? '44px' : '160px',
          zIndex: 2,
        }}
      >
        <TrianglesBackground style={{ ...(matches ? { width: 738, height: 656 } : {}) }} />
      </Box>
      <Grid container direction={'row'}>
        <Grid
          item
          xs={7}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'Barlow, Arial',
            fontSize: matches ? '80px' : '96px',
            fontWeight: 800,
            lineHeight: matches ? '100px' : '120px',
            zIndex: 3,
          }}
        >
          <span>{t('customServices', 'Custom Software Development Services')}</span>
        </Grid>
        <Grid
          item
          xs={5}
          alignSelf={'flex-end'}
          sx={{
            paddingLeft: '20px',
            color: '#FFFFFF',
            fontFamily: 'Poppins, Arial',
            fontSize: matches ? '15px' : '18px',
            fontWeight: 500,
            lineHeight: matches ? '30px' : '36px',
            zIndex: 3,
          }}
        >
          <span>
            <Trans i18nKey={'titleText'}>
              We deliver quality consultancy <br />
              and bespoke fintech technology solutions <br /> that transform your business.
            </Trans>
          </span>
        </Grid>
      </Grid>
    </CustomBox>
  ) : (
    // Mobile version
    <CustomBox
      id={'title'}
      sx={{
        position: 'relative',
        padding: '88px 24px 62px 24px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: -70,
          right: 'calc((100% - 312px)/2)',
          zIndex: 2,
        }}
      >
        <TrianglesBackground style={{ width: 312, height: 277 }} />
      </Box>
      <Grid container direction={'row'}>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: '32px',
            color: '#FFFFFF',
            fontFamily: 'Exo, Arial',
            fontSize: '40px',
            fontWeight: 800,
            lineHeight: '50px',
            zIndex: 3,
            textAlign: 'center',
          }}
        >
          <span>
            <Trans i18nKey={'customServices'}>
              Custom
              <br /> Software <br />
              Development <br />
              Services
            </Trans>
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: '20px',
            color: '#FFFFFF',
            fontFamily: 'Poppins, Arial',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '28px',
            zIndex: 3,
            textAlign: 'center',
          }}
        >
          <span>
            <Trans i18nKey={'titleText'}>
              We deliver quality consultancy <br />
              and bespoke fintech technology solutions <br />
              that transform your business.
            </Trans>
          </span>
        </Grid>
      </Grid>
    </CustomBox>
  )
}
