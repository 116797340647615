import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Grid, useMediaQuery } from '@mui/material'

export default function TextBox(
  title: string,
  text: string,
  background: string,
  picture?: React.ReactElement<HTMLElement, string>,
  reversed?: boolean,
) {
  const matches = useMediaQuery('(max-width:1350px)')
  const matchesSecond = useMediaQuery('(max-width:1200px)')
  const matchesMobile = useMediaQuery('(max-width:930px)')

  const CustomBox = styled(Box)`
    color: white;
    background: ${background || 'black'};
  `

  return !matchesMobile ? (
    <CustomBox
      id={'services'}
      sx={{ padding: matchesSecond ? '80px 44px 50px 44px' : '96px 160px' }}
    >
      <Grid container direction={reversed ? 'row-reverse' : 'row'}>
        <Grid item xs={6} container direction={'row'}>
          <Grid
            item
            sx={{
              marginBottom: matchesSecond ? '40px' : '48px',
              color: '#FFFFFF',
              fontFamily: 'Exo, Arial',
              fontSize: matches ? '40px' : '48px',
              fontWeight: 800,
              lineHeight: matches ? '60px' : '72px',
              zIndex: 2,
            }}
          >
            <span>{title}</span>
          </Grid>
          <Grid
            item
            alignSelf={'flex-end'}
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Poppins, Arial',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '36px',
            }}
          >
            <span>{text}</span>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ zIndex: '1' }}>
          {picture}
        </Grid>
      </Grid>
    </CustomBox>
  ) : (
    <CustomBox id={'services'} sx={{ padding: '36px 24px 24px 24px' }}>
      <Grid container>
        <Grid item xs={12} sx={{ zIndex: '1', marginBottom: '24px' }}>
          {picture}
        </Grid>
        <Grid item xs={12} container direction={'row'}>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '24px',
              color: '#FFFFFF',
              fontFamily: 'Exo, Arial',
              fontSize: '24px',
              fontWeight: 800,
              lineHeight: '36px',
              zIndex: 2,
              textAlign: 'center',
            }}
          >
            <span>{title}</span>
          </Grid>
          <Grid
            item
            alignSelf={'flex-end'}
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Poppins, Arial',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '28px',
              textAlign: 'center',
            }}
          >
            <span>{text}</span>
          </Grid>
        </Grid>
      </Grid>
    </CustomBox>
  )
}
