import * as yup from 'yup'
import i18n from '../i18n'

// REGEXP
export const LATINICA_ONLY_REGEXP =
  /^[ "'`&.,\-a-zA-ZA-Za-zÀ-ž](?:[ "'`&$€£.,\-a-zA-ZA-Za-zÀ-ž]){1,}$/

// VALIDATION MSG
export const MIN_LENGTH = 2

export const FIRSTNAME_IS_REQUIRED_MESSAGE = i18n.t('firstNameIsRequired', 'First Name is required')
export const LASTNAME_IS_REQUIRED_MESSAGE = i18n.t('lastNameIsRequired', 'Last Name is required')
export const LATINICA_ONLY_MESSAGE = i18n.t(
  'theFieldMustHaveLatinLettersOnly',
  'Must have at least {{min}} characters, Latin letters only',
  { min: MIN_LENGTH },
)
export const INCORRECT_EMAIL_FORMAT_MESSAGE = i18n.t(
  'incorrectEmailFormat',
  'Incorrect e-mail format',
)
// SCHEMA

export const ContactFormInputSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(FIRSTNAME_IS_REQUIRED_MESSAGE)
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE),
  lastName: yup
    .string()
    .required(LASTNAME_IS_REQUIRED_MESSAGE)
    .matches(LATINICA_ONLY_REGEXP, LATINICA_ONLY_MESSAGE)
    .test('minLength', LATINICA_ONLY_MESSAGE, (val) => !!val && val?.length >= MIN_LENGTH),
  email: yup
    .string()
    .required(i18n.t('emailIsRequired', 'Email is required'))
    .email(INCORRECT_EMAIL_FORMAT_MESSAGE),
  phone: yup.string().nullable(),
  company: yup.string().nullable(),
  message: yup.string().nullable(),
})
export type ContactFormInputType = yup.Asserts<typeof ContactFormInputSchema>
