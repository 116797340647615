import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@mui/material'

import TextBox from '../../Common/TextBox'
import { BlockBox } from '../../Common'
import { ReactComponent as ScalableSolutionsPicture } from '../../../assets/images/scalable -solutions.svg'
import { ReactComponent as MultiplatfSolutionsPicture } from '../../../assets/images/illustration-end-to-end-solutions.svg'

export default function SolutionsBlock() {
  const { t } = useTranslation()
  const matchesPictures = useMediaQuery('(max-width:1350px)')
  const matchesMobile = useMediaQuery('(max-width:930px)')

  return !matchesMobile ? (
    <BlockBox fill={'#424242'}>
      {TextBox(
        `${t('tailoredTitle', 'Tailored and scalable solutions')}`,
        `${t(
          'tailoredText',
          'We will devise an in-depth, comprehensive software implementation and deployment plan, assessing your business’ needs to deliver enhanced technologies to your end-users.',
        )}`,
        '#111111',
        <ScalableSolutionsPicture
          style={{ display: 'block', margin: 'auto', ...(matchesPictures ? { width: 456 } : {}) }}
        />,
      )}
      {TextBox(
        `${t('solutionTitle', 'Multiplatform, end-to-end solutions')}`,
        `${t(
          'solutionText',
          'We deliver highly scalable, flexible, and interoperable web, mobile, desktop, and hybrid applications that will help you reach your clients on any device.',
        )}`,
        '#111111',
        <MultiplatfSolutionsPicture
          style={{ display: 'block', margin: 'auto', ...(matchesPictures ? { width: 456 } : {}) }}
        />,
        true,
      )}
    </BlockBox>
  ) : (
    // Mobile version
    <>
      {TextBox(
        `${t('tailoredTitle', 'Tailored and scalable solutions')}`,
        `${t(
          'tailoredText',
          'We will devise an in-depth, comprehensive software implementation and deployment plan, assessing your business’ needs to deliver enhanced technologies to your end-users.',
        )}`,
        '#111111',
        <ScalableSolutionsPicture
          style={{ display: 'block', margin: 'auto', width: 312, height: 130 }}
        />,
      )}
      {TextBox(
        `${t('solutionTitle', 'Multiplatform, end-to-end solutions')}`,
        `${t(
          'solutionText',
          'We deliver highly scalable, flexible, and interoperable web, mobile, desktop, and hybrid applications that will help you reach your clients on any device.',
        )}`,
        '#424242',
        <MultiplatfSolutionsPicture
          style={{ display: 'block', margin: 'auto', width: 312, height: 130 }}
        />,
        true,
      )}
    </>
  )
}
