import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, FormState, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, createTheme, Grid, Link, ThemeProvider, useMediaQuery } from '@mui/material'

import { focusKeyPressNext, FormTextField } from '../Common'
import { ReactComponent as ButtonSvg } from '../../assets/images/button-up.svg'
import { ContactFormInputSchema, ContactFormInputType } from '../../Validations'
import { ReactComponent as Logo } from '../../assets/images/klartech-logo.svg'
import { ReactComponent as TriangPicture } from '../../assets/images/Rectangle 177.svg'

export const ContactPage: FC<{ setIsSent: (arg: boolean) => void }> = () => {
  const { t } = useTranslation()
  const matches = useMediaQuery('(max-width:1200px)')
  const matchesMobile = useMediaQuery('(max-width:930px)')
  const methods = useForm<ContactFormInputType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ContactFormInputSchema),
  })

  const { formState } = methods

  const formTheme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          input: {
            color: 'white',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            marginBottom: matchesMobile ? '32px' : matches ? '48px' : '56px',
            borderColor: 'white',
            '&::before': {
              borderBottom: '1px solid #FFFFFF !important',
              opacity: 0.4,
            },
            '&::after': {
              borderBottom: '2px solid #FFFFFF !important',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: 'white',
            opacity: 0.4,
            '&.Mui-focused': {
              color: 'white',
              opacity: 1,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            position: 'relative',
            top: matchesMobile ? '-30px' : '-40px',
          },
        },
      },
    },
  })

  // const submitHandler = useCallback(async (formData: ContactFormInputType) => {
  //   console.log(formData)
  //   setIsSent(true)
  // }, [])

  const handlerEnter = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ev: React.KeyboardEvent, fState: FormState<any>) => {
      return focusKeyPressNext(ev, fState)
    },
    [formState.isDirty],
  )

  return !matchesMobile ? (
    <Box
      id={'contact'}
      sx={{
        position: 'relative',
        backgroundColor: '#111111',
        padding: matches ? '100px 44px 141px 44px' : '120px 160px 166px 160px',
      }}
    >
      <Box sx={{ position: 'absolute', top: '-54px', right: 0 }}>
        <TriangPicture style={{ width: '492px', fill: '#424242', transform: 'rotate(-90deg)' }} />
      </Box>
      <Grid container>
        <Grid item xs={6} container direction={'row'} sx={{ color: 'white' }}>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: matches ? '32px' : '40px',
              color: '#FFFFFF',
              fontFamily: 'Exo, Arial',
              fontSize: matches ? '60px' : '72px',
              fontWeight: 800,
              lineHeight: matches ? '72px' : '88px',
            }}
          >
            {t('getInTouch', 'Get in Touch')}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              position: 'realtive',
              marginBottom: '124px',
              color: '#FFFFFF',
              fontFamily: 'Poppins',
              fontSize: matches ? '15px' : '18px',
              fontWeight: 500,
              lineHeight: matches ? '30px' : '36px',
              '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: matches ? '390px' : '425px',
                width: matches ? '420px' : '608px',
                height: '1px',
                backgroundColor: '#424242',
              },
            }}
          >
            {t('tellUs', 'Tell us more about your vision and request a quote.')}
          </Grid>
          <Grid item xs={12}>
            <Logo style={{ fill: 'white', opacity: 0.4, marginBottom: '40px' }} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '56px',
              color: '#FFFFFF',
              fontFamily: 'Poppins',
              fontSize: matches ? '13px' : '16px',
              fontWeight: 500,
              lineHeight: matches ? '24px' : '28px',
            }}
          >
            {t('copyrights', 'Copyright 2022 Klartech. All Rights Reserved.')}
          </Grid>
          <Link
            href={'#title'}
            sx={{ position: 'absolute', bottom: matches ? -15 : 0, ':hover': { opacity: 0.4 } }}
          >
            <ButtonSvg style={{ fill: '#222222', ...(matches ? { width: 200 } : {}) }} />
          </Link>
        </Grid>
        <Grid item xs={6} container sx={{ paddingTop: '50px' }}>
          <ThemeProvider theme={formTheme}>
            <FormProvider {...methods}>
              <form
                action={'mailto:admin@klartech.co'}
                method="post"
                encType="text/plain"
                // onSubmit={methods.handleSubmit(submitHandler)}
                id="forNextFocus"
                onKeyDown={(event) => {
                  handlerEnter(event, formState)
                }}
              >
                <Grid item spacing={3} container xs={12} direction={'row'}>
                  <Grid item xs={6}>
                    <FormTextField
                      label={t('firstName', 'First Name')}
                      name="firstName"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField
                      label={t('lastName', 'Last Name')}
                      name="lastName"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item spacing={3} container xs={12} direction={'row'}>
                  <Grid item xs={6}>
                    <FormTextField
                      label={t('email', 'Email Address')}
                      name="email"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField
                      label={t('phone', 'Phone Number')}
                      name="phone"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <FormTextField
                  label={t('company', 'Company Name')}
                  name="company"
                  type="text"
                  fullWidth
                />
                <FormTextField
                  label={t('message', 'Message')}
                  name="message"
                  type="text"
                  fullWidth
                />

                <Button
                  type={'submit'}
                  sx={{
                    marginTop: '48px',
                    padding: '12px',
                    width: '100%',
                    color: 'white',
                    border: '1px solid white',
                    boxShadow: '4px 4px 0px #FFFFFF',
                    textTransform: 'toUpperCase',
                    fontFamily: 'Exo',
                    fontWeight: 800,
                    fontSize: '14px',
                    lineHeight: '24px',
                    '&:hover': {
                      background: 'white',
                      borderColor: 'black',
                      color: 'black',
                    },
                  }}
                >
                  {' '}
                  {t('talkUs', 'Talk to us')}{' '}
                </Button>
              </form>
            </FormProvider>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Box>
  ) : (
    //Mobile version
    <Box
      id={'contact'}
      sx={{
        position: 'relative',
        backgroundColor: '#111111',
        padding: '50px 24px 108px 24px',
        textAlign: 'center',
      }}
    >
      <Grid container sx={{ color: 'white' }}>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: '24px',
            fontFamily: 'Exo, Arial',
            fontSize: '32px',
            fontWeight: 800,
            lineHeight: '40px',
          }}
        >
          {t('getInTouch', 'Get in Touch')}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: 'realtive',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '28px',
          }}
        >
          {t('tellUs', 'Tell us more about your vision and request a quote.')}
        </Grid>

        <Grid item xs={12} container sx={{ paddingTop: '40px' }}>
          <ThemeProvider theme={formTheme}>
            <FormProvider {...methods}>
              <form
                action={'mailto:admin@klartech.co'}
                method="post"
                encType="text/plain"
                // onSubmit={methods.handleSubmit(submitHandler)}
                id="forNextFocus"
                onKeyDown={(event) => {
                  handlerEnter(event, formState)
                }}
              >
                <FormTextField
                  label={t('firstName', 'First Name')}
                  name="firstName"
                  type="text"
                  fullWidth
                />

                <FormTextField
                  label={t('lastName', 'Last Name')}
                  name="lastName"
                  type="text"
                  fullWidth
                />

                <FormTextField
                  label={t('email', 'Email Address')}
                  name="email"
                  type="text"
                  fullWidth
                />

                <FormTextField
                  label={t('phone', 'Phone Number')}
                  name="phone"
                  type="text"
                  fullWidth
                />

                <FormTextField
                  label={t('company', 'Company Name')}
                  name="company"
                  type="text"
                  fullWidth
                />
                <FormTextField
                  label={t('message', 'Message')}
                  name="message"
                  type="text"
                  fullWidth
                />

                <Button
                  type={'submit'}
                  sx={{
                    marginTop: '48px',
                    padding: '12px',
                    width: '100%',
                    color: 'white',
                    border: '1px solid white',
                    boxShadow: '4px 4px 0px #FFFFFF',
                    textTransform: 'toUpperCase',
                    fontFamily: 'Exo',
                    fontWeight: 800,
                    fontSize: '14px',
                    lineHeight: '24px',
                    '&:hover': {
                      background: 'white',
                      borderColor: 'black',
                      color: 'black',
                    },
                  }}
                >
                  {' '}
                  {t('talkUs', 'Talk to us')}{' '}
                </Button>
              </form>
            </FormProvider>
          </ThemeProvider>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <Grid
          item
          xs={12}
          sx={{
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 'calc(100%/2 - 156px)',
              top: 48,
              width: '312px',
              height: '1px',
              backgroundColor: '#424242',
            },
          }}
        >
          <Logo style={{ fill: 'white', opacity: 0.4, marginBottom: '24px', marginTop: '88px' }} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'Poppins',
            fontSize: matches ? '13px' : '16px',
            fontWeight: 500,
            lineHeight: matches ? '24px' : '28px',
          }}
        >
          {t('copyrights', 'Copyright 2022 Klartech. All Rights Reserved.')}
        </Grid>
      </Box>

      <Link
        href={'#title'}
        sx={{
          position: 'absolute',
          bottom: -30,
          left: 'calc(100%/2 - 60px)',
          ':hover': { opacity: 0.4 },
        }}
      >
        <ButtonSvg style={{ fill: '#222222', width: 120, height: 120 }} />
      </Link>
    </Box>
  )
}
