import React, {FC, useEffect, useState} from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {Box, Grid, Link, useMediaQuery} from '@mui/material'

import { ReactComponent as Building } from '../../assets/images/illustration-building.svg'
import { ReactComponent as Figures } from '../../assets/images/home-figures-background.svg'
import { ReactComponent as Triangle } from '../../assets/images/Rectangle_sent.svg'

export const SentPage: FC<{
  setIsSent: (arg: boolean) => void
}> = ({ setIsSent }) => {
  const [timerCount, setTimerCount] = useState<number>(15)
  const matches = useMediaQuery('(max-width:1200px)')
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSent(false)
    }, 15000)
    const timerCount = setInterval(() => {
      setTimerCount((prevSecondsLeft) => (prevSecondsLeft === 0 ? 0 : prevSecondsLeft - 1))
    }, 1000)

    return () =>  {
      clearTimeout(timer)
      clearInterval(timerCount)
    }
  }, [])


  const handleClick = () => {
    setIsSent(false)
  }
  return (
    <Box
      sx={{ position: 'relative', backgroundColor: '#111111', padding: matches ? '200px 44px 370px 44px' : '337px 160px 470px 160px' }}
    >
      <Box sx={{ position: 'absolute', bottom: 0, right: matches ? 44 : 160, zIndex: 3 }}>
        <Building />
      </Box>
      <Box sx={{ position: 'absolute', bottom: -80, right: 25, zIndex: 2 }}>
        <Figures />
      </Box>
      <Box sx={{ position: 'absolute', bottom: -1, right: 0 }}>
        <Triangle style={{ fill: '#424242' }} />
      </Box>
      <Grid container sx={{ position: 'relative' }}>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: '64px',
            color: '#FFFFFF',
            fontFamily: 'Exo, Arial',
            fontSize: matches ? '60px' : '72px',
            fontWeight: 800,
            lineHeight: matches ? '72px' : '88px',
          }}
        >
          <span>{t('msgSent', 'Message Sent')}</span>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'Exo, Arial',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '36px',
          }}
        >
          <span>
            <Trans i18nKey={'redirectText'}>
              {`You will be redirected to the Home Page in ${timerCount} seconds`}
            </Trans>
            <br />
            <Trans i18nKey={'autoRedirectText'}>
              <Link
                onClick={handleClick}
                href={'#title'}
                sx={{ color: 'white', textDecorationColor: 'white' }}
              >
                Click here
              </Link>{' '}
              if you don’t wish to be redirected automatically.
            </Trans>
          </span>
        </Grid>
      </Grid>
    </Box>
  )
}
