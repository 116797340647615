import React, { ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FormInputFieldEntity extends Partial<any> {
  type?: string
  name: string
  label?: ReactNode
  id?: string
  placeholder?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateValue?: (name: string, value: any) => void
  cssProp?: string
  disabled?: boolean
  shouldValidateParam?: boolean
}

export const FormTextField: React.FC<FormInputFieldEntity> = ({
  type,
  name,
  label,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation()
  const { register, formState } = useFormContext()
  const error = formState.errors ? formState.errors[name] : null

  const nameInput = register(name)

  return (
    <TextField
      type={type ? type : 'text'}
      label={label}
      // required={false}
      id={name}
      variant={'standard'}
      placeholder={placeholder}
      error={!!error}
      helperText={error && t(error.message)}
      inputRef={nameInput.ref}
      {...nameInput}
      {...rest}
    />
  )
}
