import React, { FC } from 'react'
import ServicesBlock from './Blocks/ServicesBlock'
import SolutionsBlock from './Blocks/SolutionsBLock'
import DevelopmentBlock from "./Blocks/DevelopmentBlock";

export const ServicesPage: FC = () => {
  return (
    <>
      {ServicesBlock()}
      {SolutionsBlock()}
      {DevelopmentBlock()}
    </>
  )
}
